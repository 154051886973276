// import { useState } from 'react';

import Footer from '../components/Home/Footer';
// import Gallery from '../components/Home/Gallery';
// import Header from '../components/Home/Header';
// import Iframe from '../components/Home/Iframe';
// import Slider from '../components/Home/Slider';
import Content from '../content/Home.json';

/**
 * Home Page
 *
 * @return {*}
 */
function Home() {
	// const [link, setIframeLink] = useState(false);

	return (
		<div className="sm:hidden">
			{/* <Header Title={Content.Name} Image={Content.Logo} />
			<Slider Slides={Content.Slider} setIframeLink={setIframeLink} />
			<Gallery Items={Content.Gallery} setIframeLink={setIframeLink} /> */}
			<Footer Tabs={Content.Footer} />
		</div>
	);
}

export default Home;
