import cx from 'classnames';
import PropTypes from 'prop-types';

/**
 * Component for Fixed Footer
 *
 * @component
 * @example
 * const Tabs = []
 * const setIframeLink = () => {}
 * return (
 *   <Footer Tabs={Tabs} setIframeLink={setIframeLink} />
 * )
 */
const Footer = ({ Tabs }) => {
	return (
		<div className="fixed bottom-0 z-50 flex w-full px-5 bg-[#e3e8ec] shadow-md left-1/2 rounded-t-3xl transform-gpu -translate-x-2/4">
			{Tabs.map((item, key) => (
				<Tab key={key} {...item} />
			))}
		</div>
	);
};

Footer.propTypes = {
	/**
	 * Array of Tabs
	 */
	Tabs: PropTypes.array.isRequired
	// /**
	//  * Show / Set Iframe Link Function
	//  */
	// setIframeLink: PropTypes.func.isRequired
};

/**
 * Footer Tab Component
 *
 * @component
 * @example
 * const Name = "Example"
 * const Icon = "fas fa-home"
 * const link = "/"
 * const setIframeLink = () => {}
 * return (
 *   <Tab name={Name} icon={Icon} Link={link} setIframeLink={setIframeLink} />
 * )
 */
const Tab = ({ name, icon, link }) => {
	return (
		<div className="flex-1 group">
			<button
				onClick={() => (window.location.href = link)}
				className="border-0 bg-[#e3e8ec] outline-none flex items-end justify-center w-full p-2.5 mx-auto text-center text-gray-700">
				<span className="block">
					<i className={cx(['block pt-1 mb-1.5 text-base font-bold', icon])}></i>
					<span className="block pb-1 text-base font-bold">{name}</span>
				</span>
			</button>
		</div>
	);
};

Tab.propTypes = {
	/**
	 * Name of Tab To Display
	 */
	name: PropTypes.string.isRequired,
	/**
	 * Icon of Tab To Display
	 */
	icon: PropTypes.string.isRequired,
	/**
	 * Link For Tab To Display In Iframe
	 */
	link: PropTypes.string.isRequired,
	/**
	 * Show / Set Iframe Link Function
	 */
	setIframeLink: PropTypes.func.isRequired
};

export default Footer;
